.smish {
    flex: 4;
    padding: 20px;
    max-width: 600px;
    margin: auto;
}

.smishContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.msgInfo{
    display: flex;
    align-items: center;
    /* margin: 6px 0px; */
    color: #444;
}

.smishImg {
    justify-content: center;
}

.messageInfoDisplay{
    align-items: center;
    margin: 10px 0px;
    color: #444;
}

.msgSectionTitle {
    font-size: large;
    font-weight: 600;
    color: rgb(175, 170, 170);
    margin: 10px 0;
}

.vtResults {
    text-align:center;
}

.whoisResults {
    /* text-align: center; */
    font-weight: bold;
}

.msgSection {
    display: flex;
    align-items: center;
}

.messageContentField, .URLlinkdata {
    font-weight: bold;
    font-size: medium;
    overflow-wrap: anywhere;
}

.pageTitle {
    font-size: x-large;
    font-weight: bolder;
}

.messagePicture {
    max-width: 100%; 
}

.paperStyling {
    padding:10px;
}

.SmishTitleContainer {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Shortened {
    text-align: left;
    font-weight: bold;
    color: red;
    user-select: none;
}