.addSmish {
    flex: 4;
    padding: 20px;
    max-width: 600px;
    margin: auto;
}
.SmishTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.SmishFormContainer {
    flex: 2;
    -webkit-box-shadow: 5px 5px 15px -10px rgba(0,0,0,0.75); 
    box-shadow: 5px 5px 15px -10px rgba(0,0,0,0.75);
    padding:20px
}

.messageDetails {
    font-size: 16px;
    font-weight: bold;
}

.addSmishtitle {
    font-size: 13px;
    font-weight: bold;
}

.insertMessageText {
    min-width: 155px;
}

.insertMessageDate {
    min-width: 155px;
}

.insertMessageImage {
    display: none;
}

.messageInfo {
    display: flex;
    justify-content: space-between;
    margin: 20px 0px;
}

.messageInfo label{
    align-items: center;
}

.label-align {
    padding: 5px;
    vertical-align: super;
    text-align: left;
}

.buttonStyle { 
    background-color: #1976d2 !important;
    color: white !important;
}

.buttonStyle:hover {
    background-color: #175797 !important;
}