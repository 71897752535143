.register {
    flex: 4;
    padding: 20px;
    max-width: 600px;
    margin: auto;
}
.SmishTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.RegisterFormContainer {
    flex: 2;
    -webkit-box-shadow: 5px 5px 15px -10px rgba(0,0,0,0.75); 
    box-shadow: 5px 5px 15px -10px rgba(0,0,0,0.75);
    padding:20px
}

.messageDetails {
    font-size: 2rem;
    font-weight: bold;
}

.messageInfo {
    display: flex;
    justify-content: space-between;
    margin: 20px 0px;
}

.messageInfo label{
    align-items: center;
}

.label-align {
    padding: 5px;
    vertical-align: super;
}