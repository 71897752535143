.nameTitle {
    font-weight: bold;
}
.people {
    max-width: 400px;
    margin: 0 auto;
    padding-bottom: 5px;
}
.peopleContainer {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
}

.pictureCardContainer{
    padding: 1px 10px;
}

.pictureCard {
    background-color: rgba(0,77,150,0.06);
    font-family: Roboto !important;
    border-radius: 0.5rem;
    margin: 10px auto;
    max-width: 400px;
}

.pfpimage {
    border-radius: 50%;
    width:200px;
}

.cardText {
    padding: 5px 0;
}

.Titleblock {
    text-align: start;
    padding-left: 20px;
}

.profLink {
    text-decoration: none;
    color:#0078CC;
    font-weight: 600;
}

.profLink:hover {
    color:#1B4CAB;
}