.dataset {
    max-width: 900px;
    margin: 0 auto;
    padding-bottom: 5px;
}
.datasetContainer {
    text-align: left;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
}

.datasetInfoContainer{
    padding: 1px 10px;
}

.Titleblock {
    text-align: start;
    padding-left: 20px;
}

.emphasizedText {
    font-weight: bold;
}

.react-pdf__Page__canvas {
    margin: 0 auto;
    max-width: 960px;
    width: 100% !important;
    height: 100% !important;
}
.react-pdf__Page__annotations, .react-pdf__Page__textContent  {
    display:none;
}