.privacy {
    max-width: 900px;
    margin: 0 auto;
    padding-bottom: 5px;
}
.privacyContainer {
    text-align: left;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
}

.privacyPolicyContainer{
    padding: 1px 10px;
}

.Titleblock {
    text-align: start;
    padding-left: 20px;
}

.emphasizedText {
    text-decoration: underline;
    font-style: italic;
    font-weight:400;
}